import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PL from './locales/pl';
import EN from './locales/en';

i18n.use(initReactI18next).init({
  fallbackLng: 'pl',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  languages: ["pl", "en"],
  resources: {
    pl: PL,
    en: EN
  },
});

export default i18n;