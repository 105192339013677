export default {translation: {
    "tytul": "Pieczęć Kwalifikowana",
    "o-nas": "Dlaczego warto",
    "nasze-marki": "Nasze produkty",
    "historia": "Historia",
    "voucher": "Voucher",
    "kontakt": "Kontakt",
    "tekst-gora": "Zabezpiecz swoje dokumenty cyfrowo! Wykorzystaj pieczęć kwalifikowaną - niezawodne narzędzie do potwierdzania autentyczności i integralności elektronicznych dokumentów. Ochrona przed fałszerstwem i nieautoryzowanymi zmianami. Znakomicie ułatwia rejestrację dokumentów w KSeF (Krajowy System ewidencji Faktur).",
    "sprawdz": "Sprawdź już teraz! ",
    "pieczatka": "Pieczątka elektroniczna ",
    "w-bydgoszczy": "Pieczątka kwalifikowana z weryfikacją tożsamości w Bydgoszczy",
    "bezpieczne-niezawodne": "Bezpieczne i niezawodne. Pieczęć kwalifikowana dla Twoich dokumentów",
    "co-to": "Co to jest pieczęć elektroniczna? ",
    "co-to-tekst": "Jest to kwalifikowana usługa zaufania wydawana dla podmiotów posiadających osobowość prawną (np. firmy, instytucje, podmioty publiczne). Umożliwia ona pieczętowanie dowolnych dokumentów, plików przy użyciu jedynie danych firmowych. ",
    "co-daje": "Co może Ci zagwarantować pieczęć elektroniczna? ",
    "co-daje-lista-1": "Autentyczność dokumentu –  źródło pochodzenia dokumentu, możliwość zidentyfikowania podmiotu, który wydał dany dokument, ",
    "co-daje-lista-2": "Integralność dokumentu – gwarancja niezmienności danych od czasu opieczętowania, ",
    "co-daje-lista-3": "Ochrona danych osobowych - w pieczęci znajdują się jedynie dane podmiotu (nazwa oraz NIP), ",
    "co-daje-lista-4": "Przyspieszenie procesów - umożliwia automatyzację procesów i znakowania dokumentów, istnieje możliwość integracji z systemami generującymi dokumenty. ",
    "gdzie-uzyc": "Możliwości zastosowania kwalifikowanej pieczęci elektronicznej ",
    "gdzie-uzyc-lista-1": "wydawanych zaświadczeń urzędowych, ",
    "gdzie-uzyc-lista-2": "firmowej korespondencji elektronicznej, ",
    "gdzie-uzyc-lista-3": "dokumentów prawnych, ",
    "gdzie-uzyc-lista-4": "dokumentów dotyczących zaproszeń do złożenia oferty, postępowań przetargowych, ",
    "gdzie-uzyc-lista-5": "faktur elektronicznych, ",
    "gdzie-uzyc-lista-6": "potwierdzeń zawarcia transakcji w usługach online. ",
    "nasze-produkty": "NASZE PRODUKTY ",
    "card-1": "Pieczęć Elektroniczna",
    "card-2": "Pieczęć Elektroniczna HSM",
    "card-3": "Pieczęć elektroniczna PSD2",
    "umow-wizyte": "Umów się na wizytę ",

    
    "pieczec-elektroniczna-tytul": "Pieczęć elektroniczna",
    "pieczec-elektroniczna-desc1": "Certyfikat kwalifikowany EuroCert - ważny 2 lata",
    "pieczec-elektroniczna-desc2": "Karta kryptograficzna lub karta+czytnik ",
    "pieczec-elektroniczna-desc3": "Oprogramowanie do składania i weryfikacji pieczęci elektronicznej ",
    "pieczec-elektroniczna-desc4": "Usługa weryfikacji danych podmiotu ",
    "pieczec-elektroniczna-desc5": "Zdalne wsparcie techniczne. ",
    "pieczec-elektroniczna-cena": "Od: 1 597,77 zł (netto: 1 299,00 zł ) ",
    "pieczec-elektroniczna-opis": "Pieczęć elektroniczna  jest to kwalifikowana usługa zaufania wydawana dla podmiotów posiadających osobowość prawną (np. firmy, instytucje, podmioty publiczne). Umożliwia ona pieczętowanie dowolnych dokumentów, plików przy użyciu jedynie danych firmowych. ",

    "pieczec-elektroniczna-hsm-tytul": "Pieczęć elektroniczna HSM ",
    "pieczec-elektroniczna-hsm-desc1": "Certyfikat kwalifikowany EuroCert - ważny 2 lata, ",
    "pieczec-elektroniczna-hsm-desc2": "Oprogramowanie do składania pieczęci elektronicznej, ",
    "pieczec-elektroniczna-hsm-desc3": "Usługa weryfikacji danych podmiotu, ",
    "pieczec-elektroniczna-hsm-desc4": "Zdalne wsparcie techniczne. ",
    "pieczec-elektroniczna-hsm-desc5": "null",
    "pieczec-elektroniczna-hsm-cena": "Chcesz dowiedzieć się więcej? Napisz do nas!",
    "pieczec-elektroniczna-hsm-opis": "Pieczęć elektroniczna HSM - jest to kwalifikowana usługa zaufania wydawana dla podmiotów posiadających osobowość prawną (np. firmy, instytucje, podmioty publiczne). Umożliwia ona pieczętowanie dowolnych dokumentów, plików przy użyciu jedynie danych firmowych. Pieczęć jest przetrzymywana na bezpiecznym urządzeniu kryptograficznym HSM (Hardware security module). HSM może znajdować się w siedzibie zamawiającego lub w infrastrukturze EuroCert. ",

    "pieczec-elektroniczna-psd2-tytul": "Pieczęć elektroniczna PSD2 ",
    "pieczec-elektroniczna-psd2-desc1": "Kwalifikowana pieczęć elektroniczna (QSEAL PSD2)  ",
    "pieczec-elektroniczna-psd2-desc2": "Kwalifikowany certyfikat uwierzytelniania witryn internetowych (QWAC PSD2), Powyższe pozwalają spełnić wymogi i regulacje wynikające z dyrektywy PSD2, nałożone na dostawców usług płatniczych prowadzących rachunki (ASPSP) i zewnętrznych dostawców usług płatniczych (TPP) działających na terenie Unii Europejskiej.",
    "pieczec-elektroniczna-psd2-desc3": "Autentyczność, poufność i integralność komunikacji, ",
    "pieczec-elektroniczna-psd2-desc4": "Dostarczają prawnie wiążących dowodów dotyczących transakcji i treści. ",
    "pieczec-elektroniczna-psd2-desc5": "null",
    "pieczec-elektroniczna-psd2-cena": "Produkt \"Pieczęć elektroniczna PSD2\" nie zawiera w sobie certyfikatu uwierzytelniania witryn internetowych QWAC.",
    "pieczec-elektroniczna-psd2-opis": `
    <h2 class="blue">Jakich certyfikatów potrzebuje dostawca usług płatniczych zgodnie z dyrektywą PSD2?</h2>
    <br />
    Taki podmiot jest zobowiązany do posiadania dwóch rodzajów certyfikatów kwalifikowanych:
    <ul>
      <li><b>Kwalifikowany certyfikat uwierzytelniania witryn internetowych (QWAC PSD2)</b> - który umożliwia weryfikuję tożsamość podmiotu, szyfrowanie i uwierzytelnianie przesyłanych dane w komunikacji peer-to-peer.
      </li>
      <li><b>Kwalifikowana pieczęć elektroniczna (QSEAL PSD2)</b> - w celu złożenia podpisu cyfrowego pod przesyłanymi danymi oraz zapewnienia ich integralności i autentyczności. Certyfikat pieczęci zawiera informacje o podmiocie i pełnionej przez niego roli:
      <ul>
        <li>dostawca usług płatniczych prowadzący rachunek (ASPSP),</li>
        <li>dostawca usług dostępu do informacji o rachunku (AISP),</li>
        <li>dostawca usług inicjowania transakcji płatniczej (PISP),</li>
        <li>dostawca usług płatniczych wydający instrumenty płatnicze oparte na karcie (PIISP).</li>
      </ul>
      </li>
    </ul>
    <br />
    Jeśli jesteś zainteresowany zakupem zestawu certyfikatów PSD2 - napisz do nas! 
    <h2 class="blue">W zależności od wybranego produktu możesz zakupić:</h2>
    <ul>
      <li>Certyfikat pieczęci elektronicznej QSEAL PSD2
      </li>
      <li>Certyfikat uwierzytelniania witryn internetowych QWAC PSD2
      </li>
      <li>Certyfikat testowy QWAC PSD2 i QSEAL PSD2
      </li>
    </ul>
    <h2 class="blue">Certyfikaty PSD2 przeznaczone są dla:</h2>
    <ul>
      <li>Banków,</li>
      <li>Instytucji płatniczych,</li>
      <li>Niezależni operatorzy bankomatów,</li>
      <li>Niebankowi dostawcy usług płatniczych,</li>
      <li>Dystrybutorzy kart sklepowych i paliwowych,</li>
    </ul>
    `,

    "dodatkowa-usluga": "Skorzystaj z dodatkowej usługi:",
    "dodatkowa-usluga-opis": "Pakiet kwalifikowanych znaczników czasu - jest to dodatkowa usługa umożliwiająca potwierdzenie powstania lub podpisania dokumentu w konkretnym dniu i godzinie. Umożliwia ona zabezpieczenie pliku przed sfałszowaniem lub antydatowaniem. Czas, którym znakowany jest dokument, nie wynika z czasu systemowego (stacji roboczej lub serwera), lecz pochodzi z niezależnego źródła, jakim jest Zaufana Trzecia Strona.",
  }
}
