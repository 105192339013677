import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './views/Home/App';
import i18n from "./i18n";
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ProductDesc from './views/ProductDesc/ProductDesc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/pieczec-elektroniczna" element={<ProductDesc name="pieczec-elektroniczna" />} />
          <Route path="/pieczec-elektroniczna-hsm" element={<ProductDesc name="pieczec-elektroniczna-hsm" />} />
          <Route path="/pieczec-elektroniczna-psd2" element={<ProductDesc name="pieczec-elektroniczna-psd2" />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
