import './Footer.sass';
import facebook from '../../assets/icons/facebook.svg';
import phone from '../../assets/icons/phone.svg';
import m1 from '../../assets/images/bluserwer.webp';
import m2 from '../../assets/images/blustream.webp';
import m3 from '../../assets/images/e-pulpit24.webp';
import m4 from '../../assets/images/m4.webp';
import m5 from '../../assets/images/m5.webp';
import m6 from '../../assets/images/360.webp';
import m7 from '../../assets/images/adshoot.webp';
import map from '../../assets/images/map.webp'
import { t } from 'i18next';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="grid">
        <div>
          <div className="grid__row">
            {/* <a href="facebook.com">
              <img className="icon" src={facebook} alt="FB" />
            </a> */}
            <a href="tel:+48525222249">
              <img className="icon" src={phone} alt="Tel" />
            </a>
          </div>
          <br />
          <div className="grid__row">
            ul. Kijowska 42
          </div>
          <div className="grid__row">
            85-703 Bydgoszcz
          </div>
          <br />
          <div className="grid__row">
            <a href="tel:+48525222249">(+48) 52 522 22 49</a>
          </div>
          <div className="grid__row">
            <a href="mailto:krzysztof.chmura@podpis-elektroniczny.bydgoszcz.pl">krzysztof.chmura@podpis-elektroniczny.bydgoszcz.pl</a>
          </div>
          <br />
          <div className="grid__row">
            <b>{t("nasze-marki")}:</b>
          </div>
        </div>
        <div>
          <div className="grid__row grid__row--right">
            <a href="tel:525222249" className="call-button">
              {t("umow-wizyte")}
              <br />
              (+48) 52 522 22 49
            </a>
          </div>
          <br />
          <div className="grid__row">
            <a href="https://maps.app.goo.gl/7CpakZ6Lg1MT1M5b7">
              <img className="map" src={map} alt="&nbsp;" />
            </a>
          </div>
        </div>
      </div>
      <div className="our-brands">
        <img src={m1} alt="BluSerwer" />
        <img src={m2} alt="BluStreamTV" />
        <img src={m3} alt="e-Pulpit24.pl" />
        <img src={m4} alt="Blu-Offiice" />
        <img src={m5} alt="BluDrive24" />
        <img src={m6} alt="Immunify360" />
        <img src={m7} alt="AdShoot" />
        </div>
    </footer>
  )
}

export default Footer;