export default {translation: {
    "tytul": "Qualified Stamp",
    "o-nas": "Why worth it",
    "nasze-marki": "Our products",
    "historia": "History",
    "voucher": "Voucher",
    "kontakt": "Contact",
    "tekst-gora": "Secure your documents digitally! Use a qualified seal - a reliable tool for confirming the authenticity and integrity of electronic documents. Protection against forgery and unauthorized changes. Superbly facilitates the registration of documents in KSeF (National Invoice Recording System).",
    "sprawdz": "Check now!",
    "pieczatka": "Digital stamp",
    "w-bydgoszczy": "Qualified stamp with identity verification in Bydgoszcz",
    "bezpieczne-niezawodne": "Secure and reliable. Qualified Seal for your documents",
    "co-to": "What is an electronic seal? ",
    "co-to-tekst": "It is a qualified trust service issued to entities with legal personality (e.g. companies, institutions, public entities). It allows sealing any documents, files using only company data. ",
    "co-daje": "What can an electronic seal guarantee you? ",
    "co-daje-lista-1": "Authenticity of the document - the source of the document, the ability to identify the entity that issued the document, ",
    "co-daje-lista-2": "Integrity of the document - a guarantee of immutability of data from the time of sealing, ",
    "co-daje-lista-3": "Data protection - the seal contains only the data of the entity (name and TIN), ",
    "co-daje-lista-4": "Accelerate processes - allows automation of processes and marking of documents, there is the possibility of integration with document generating systems. ",
    "gdzie-uzyc": "Possibilities of using a qualified electronic seal ",
    "gdzie-uzyc-lista-1": "official certificates issued, ",
    "gdzie-uzyc-lista-2": "corporate e-mail correspondence, ",
    "gdzie-uzyc-lista-3": "legal documents, ",
    "gdzie-uzyc-lista-4": "Documents relating to bid invitations, tender proceedings, ",
    "gdzie-uzyc-lista-5": "electronic invoices, ",
    "gdzie-uzyc-lista-6": "confirmations of the conclusion of transactions in online services. ",
    "nasze-produkty": "OUR PRODUCTS ",
    "card-1": "Digital Stamp",
    "card-2": "Digital Stamp HMS",
    "card-3": "Digital Stamp PSD2",
    "umow-wizyte": "Make an appointment ",

    "pieczec-elektroniczna-tytul": "Electronic Seal",
    "pieczec-elektroniczna-desc1": "Qualified EuroCert Certificate - valid for 2 years",
    "pieczec-elektroniczna-desc2": "Cryptographic card or card+reader",
    "pieczec-elektroniczna-desc3": "Software for creating and verifying electronic seals",
    "pieczec-elektroniczna-desc4": "Entity data verification service",
    "pieczec-elektroniczna-desc5": "Remote technical support",
    "pieczec-elektroniczna-cena": "From: 1,597.77 PLN (net: 1,299.00 PLN)",
    "pieczec-elektroniczna-opis": "The electronic seal is a qualified trust service issued to legal entities (e.g., companies, institutions, public entities). It allows the sealing of any documents and files using only company data.",
  
    "pieczec-elektroniczna-hsm-tytul": "HSM Electronic Seal",
    "pieczec-elektroniczna-hsm-desc1": "Qualified EuroCert Certificate - valid for 2 years",
    "pieczec-elektroniczna-hsm-desc2": "Software for creating electronic seals",
    "pieczec-elektroniczna-hsm-desc3": "Entity data verification service",
    "pieczec-elektroniczna-hsm-desc4": "Remote technical support",
    "pieczec-elektroniczna-hsm-desc5": "null",
    "pieczec-elektroniczna-hsm-cena": "Want to learn more? Contact us!",
    "pieczec-elektroniczna-hsm-opis": "HSM Electronic Seal is a qualified trust service issued to legal entities (e.g., companies, institutions, public entities). It allows the sealing of any documents and files using only company data. The seal is stored on a secure cryptographic hardware device (HSM - Hardware Security Module). The HSM can be located at the customer's premises or in EuroCert's infrastructure.",
  
    "pieczec-elektroniczna-psd2-tytul": "PSD2 Electronic Seal",
    "pieczec-elektroniczna-psd2-desc1": "Qualified electronic seal (QSEAL PSD2)",
    "pieczec-elektroniczna-psd2-desc2": "Qualified Website Authentication Certificate (QWAC PSD2). These allow compliance with the requirements and regulations of the PSD2 directive imposed on account-servicing payment service providers (ASPSPs) and third-party payment service providers (TPPs) operating in the European Union.",
    "pieczec-elektroniczna-psd2-desc3": "Authenticity, confidentiality, and integrity of communication",
    "pieczec-elektroniczna-psd2-desc4": "Legally binding evidence of transactions and content",
    "pieczec-elektroniczna-psd2-desc5": "null",
    "pieczec-elektroniczna-psd2-cena": "The \"PSD2 Electronic Seal\" product does not include the Website Authentication Certificate QWAC.",
    "pieczec-elektroniczna-psd2-opis": "<h2 class=\"blue\">What certificates does a payment service provider need according to the PSD2 directive?</h2><br /><br />Such an entity is obliged to have two types of qualified certificates:<ul><li><b>Qualified Website Authentication Certificate (QWAC PSD2)</b> - which allows verifying the identity of the entity, encrypting and authenticating data in peer-to-peer communication.</li><li><b>Qualified Electronic Seal (QSEAL PSD2)</b> - for digitally signing transmitted data and ensuring their integrity and authenticity. The seal certificate contains information about the entity and the role it performs:<ul><li>Account-servicing payment service provider (ASPSP),</li><li>Account information service provider (AISP),</li><li>Payment initiation service provider (PISP),</li><li>Card-based payment instrument issuer service provider (PIISP).</li></ul></li></ul><br />If you are interested in purchasing a set of PSD2 certificates, please contact us!<h2 class=\"blue\">Depending on the selected product, you can purchase:</h2><ul><li>QSEAL PSD2 Electronic Seal Certificate</li><li>QWAC PSD2 Website Authentication Certificate</li><li>QWAC PSD2 and QSEAL PSD2 Test Certificate</li></ul><h2 class=\"blue\">PSD2 certificates are intended for:</h2><ul><li>Banks,</li><li>Payment institutions,</li><li>Independent ATM operators,</li><li>Non-bank payment service providers,</li><li>Distributors of store and fuel cards.</li></ul>",
  
    "dodatkowa-usluga": "Take advantage of an additional service:",
    "dodatkowa-usluga-opis": "Qualified Timestamp Mark Package - this is an additional service that allows confirming the creation or signing of a document on a specific date and time. It allows protecting a file from forgery or backdating. The time at which the document is timestamped does not come from the system time (workstation or server) but comes from an independent source, such as a Trusted Third Party."

  }
}
