import './Card.sass';

const Card = ({ image, title, link }) => {

  return (
      <a href={link} className="card">
        <img src={image} alt={title} />
        <div>
          {title}
        </div>
      </a>
  )
}

export default Card;