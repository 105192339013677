import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Landing from "../../components/Landing/Landing";
import './App.sass';
import blusoftLogo from '../../assets/images/blusoft_blue_1.webp';
import Card from "../../components/Card/Card";
import Card1 from "../../assets/images/card1.webp";
import Card2 from "../../assets/images/card2.webp";
import Card3 from "../../assets/images/card3.webp";
import Footer from "../../components/Footer/Footer";

const App = () => {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Header />
      <Landing />
      <div className="content">
        <section>
          <p>{t("tekst-gora")}</p>
          <h1>{t("sprawdz")}</h1>
        </section>
        <section className="col2">
          <div className="left-top-rounded">
            <p>{t("pieczatka")}</p>
            <p>{t("w-bydgoszczy")}</p>
          </div>
          <div className="line"></div>
          <div>
            <img src={blusoftLogo} alt="BluSoft" />
          </div>
        </section>
        <section id="dlaczego-warto">
          <div className="right-bottom-rounded">
            {t("bezpieczne-niezawodne")}
          </div>
        </section>
      </div>
      <div className="content content--primary">
        <section>
          <h2>{t("co-to")}</h2>
          <p>{t("co-to-tekst")}</p>
        </section>
        <section>
          <h2>{t("co-daje")}</h2>
          <ul>
            <li>{t("co-daje-lista-1")}</li>
            <li>{t("co-daje-lista-2")}</li>
            <li>{t("co-daje-lista-3")}</li>
            <li>{t("co-daje-lista-4")}</li>
          </ul>
        </section>
        <section>
          <h2>{t("gdzie-uzyc")}</h2>
          <ul>
            <li>{t("gdzie-uzyc-lista-1")}</li>
            <li>{t("gdzie-uzyc-lista-2")}</li>
            <li>{t("gdzie-uzyc-lista-3")}</li>
            <li>{t("gdzie-uzyc-lista-4")}</li>
            <li>{t("gdzie-uzyc-lista-5")}</li>
            <li>{t("gdzie-uzyc-lista-6")}</li>
          </ul>
        </section>
      </div>
      <div className="content">
        <section>
          <p className="heading" id="nasze-produkty">{t("nasze-produkty")}</p>
          <div className="products">
            <Card
              title={t("card-1")}
              image={Card1}
              link="/pieczec-elektroniczna"
            />
            <Card
              title={t("card-2")}
              image={Card2}
              link="/pieczec-elektroniczna-hsm"
            />
            <Card
              title={t("card-3")}
              image={Card3}
              link="/pieczec-elektroniczna-psd2"
            />
          </div>
        </section>
      </div>
      <span id="kontakt"></span>
      <Footer />
    </div>
  )
}

export default App;