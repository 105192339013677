import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem("lng", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    // window.location.href = window.location.href;
  }

  return (
    <select onChange={handleChangeLanguage} defaultValue={i18n.language}>
      <option value="pl" style={{color: "black"}}>PL</option>
      <option value="en" style={{color: "black"}}>EN</option>
    </select>
  )
}

export default LanguageSelector;