import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const ProductDesc = ({name}) => {
  const { t } = useTranslation();
  const products = {
    "pieczec-elektroniczna": {
      title: t("pieczec-elektroniczna-tytul"),
      list: [
        t("pieczec-elektroniczna-desc1"),
        t("pieczec-elektroniczna-desc2"),
        t("pieczec-elektroniczna-desc3"),
        t("pieczec-elektroniczna-desc4"),
        t("pieczec-elektroniczna-desc5")
      ],
      price: t("pieczec-elektroniczna-cena"),
      desc: t("pieczec-elektroniczna-opis"),
      top: false
    },
    "pieczec-elektroniczna-hsm": {
      title: t("pieczec-elektroniczna-hsm-tytul"),
      list: [
        t("pieczec-elektroniczna-hsm-desc1"),
        t("pieczec-elektroniczna-hsm-desc2"),
        t("pieczec-elektroniczna-hsm-desc3"),
        t("pieczec-elektroniczna-hsm-desc4"),
        t("pieczec-elektroniczna-hsm-desc5")
      ],
      price: t("pieczec-elektroniczna-hsm-cena"),
      desc: t("pieczec-elektroniczna-hsm-opis"),
      top: false
    },
    "pieczec-elektroniczna-psd2": {
      title: t("pieczec-elektroniczna-psd2-tytul"),
      list: [
        t("pieczec-elektroniczna-psd2-desc1"),
        t("pieczec-elektroniczna-psd2-desc2"),
        t("pieczec-elektroniczna-psd2-desc3"),
        t("pieczec-elektroniczna-psd2-desc4"),
        t("pieczec-elektroniczna-psd2-desc5")
      ],
      price: t("pieczec-elektroniczna-psd2-cena"),
      desc: t("pieczec-elektroniczna-psd2-opis"),
      top: true
    }
  }

  const toHTML = (str) => {
    return <div className="black" dangerouslySetInnerHTML={{__html: str}} />;
  }

  return (
    <div className="App">
      <Header />
      <br />
      <br />
      <br />
      <br />
      <div className="content">
        <section className={`col2 ${products[name]["top"] ? "top" : "" }`}>
          <div className="black">
            <h1 className="blue">{products[name]["title"]}</h1>
            <ul>
            {products[name]["list"][0] !== "null" ? <li>{products[name]["list"][0]}</li> : null}
            {products[name]["list"][1] !== "null" ? <li>{products[name]["list"][1]}</li> : null}
            {products[name]["list"][2] !== "null" ? <li>{products[name]["list"][2]}</li> : null}
            {products[name]["list"][3] !== "null" ? <li>{products[name]["list"][3]}</li> : null}
            {products[name]["list"][4] !== "null" ? <li>{products[name]["list"][4]}</li> : null}
            </ul>
            <h3>{products[name]["price"]}</h3>
            <br />
            <h2>{t("dodatkowa-usluga")}</h2>
            <span>{t("dodatkowa-usluga-opis")}</span>
          </div>
          <div className="line"></div>
          <div className="black">
            {toHTML(products[name]["desc"])}
          </div>
        </section>
        <section>

        </section>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default ProductDesc;