// COMPONENT
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

// STYLES
import './Header.sass';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("lng") || i18n.language);

  const expandCollapseMenu = () => {
    document.querySelector("nav").classList.toggle("expanded");
    document.querySelector(".svg").classList.toggle("svg--active");
  }

  const changeLanguage = () => {
    if (currentLanguage === "pl") {
      localStorage.setItem("lang", "en")
      i18n.changeLanguage("en");
      setCurrentLanguage("en");
    }
    else {
      localStorage.setItem("lang", "pl")
      i18n.changeLanguage("pl");
      setCurrentLanguage("pl");
    }
  }

  useEffect(() => {
    if (window.scrollY > 10) document.querySelector(".header").className = "header header--not-on-top";
    const scrollListener = window.addEventListener('scroll', () => {
      if (window.scrollY > 10) document.querySelector(".header").className = "header header--not-on-top";
      else document.querySelector(".header").className = "header";
    });
  }, []);

  return (
    <header className="header">
      <a href="/">
        <h1>{t("tytul")}</h1>
      </a>
      <div className="header__mobile-nav-button" onClick={expandCollapseMenu}>
        <svg width="40" height="32" className="svg">
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
        </svg>
      </div> 
      <nav onClick={expandCollapseMenu}>
        <a className="header__option" href="/#dlaczego-warto">{t("o-nas")}</a>
        <a className="header__option" href="/#nasze-produkty">{t("nasze-marki")}</a>
        <a className="header__option" href="/#kontakt">{t("kontakt")}</a>
        <LanguageSelector />
      </nav>
    </header>
  )
}

export default Header;