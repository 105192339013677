import { useTranslation } from 'react-i18next';
import pieczec from '../../assets/images/logo_red.webp';
import './Landing.sass';

const Landing = () => {
  const { t } = useTranslation();
  return (
    <div className="landing">
      <img src={pieczec} alt={t("tytul")} />
    </div>
  )
}

export default Landing;